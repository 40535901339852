<form nz-form
      [formGroup]="conductLessonFormService.lessonForm"
      nzLayout="vertical">
  <nz-form-item>
    <nz-form-label [nzRequired]="!conductLessonFormService.isSimplifiedValidation()"
                   nzFor="subject">
      {{ 'calendar.conduct-lesson.subject' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="errorTplSubject">
      <input nz-input
             formControlName="subject"
             [placeholder]="'calendar.conduct-lesson.subject' | translate" />

      <ng-template #errorTplSubject
                   let-control>
        @if (control.errors?.['required']) {
          {{ 'main.form.errors.required-tip' | translate }}
        }

        @if (control.errors?.['maxlength']) {
          {{ 'main.form.errors.max-length-255' | translate }}
        }
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzRequired]="!conductLessonFormService.isSimplifiedValidation()"
                   nzFor="homeworkCompletion">
      {{ 'calendar.conduct-lesson.previous-homework-completion' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
      <nz-radio-group
        formControlName="homeworkCompletion">
        <label nz-radio-button
               [nzValue]="HomeWorkCompletion.DONE">
          {{'calendar.conduct-lesson.homework-completion.done' | translate}}
        </label>
        <label nz-radio-button
               [nzValue]="HomeWorkCompletion.UNDONE">
          {{'calendar.conduct-lesson.homework-completion.undone' | translate}}
        </label>
        <label nz-radio-button
               [nzValue]="HomeWorkCompletion.PARTIALLY_DONE">
          {{'calendar.conduct-lesson.homework-completion.partially_done' | translate}}
        </label>
        <label nz-radio-button
               [nzValue]="HomeWorkCompletion.NO_TASK">
          {{'calendar.conduct-lesson.homework-completion.no-task' | translate}}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzRequired]="!conductLessonFormService.isSimplifiedValidation()"
                   nzFor="isStudentCameraEnabled">
      {{ 'calendar.conduct-lesson.is-student-camera-enabled' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
      <nz-radio-group
        class="camera-status-radio-group"
        formControlName="isStudentCameraEnabled">
        <label nz-radio-button
               [nzValue]="true">
          {{'yes' | translate}}
        </label>
        <label nz-radio-button
               [nzValue]="false">
          {{'no' | translate}}
        </label>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [nzRequired]="!conductLessonFormService.isSimplifiedValidation()"
                   nzFor="homework">
      {{ 'calendar.conduct-lesson.homework' | translate }}
    </nz-form-label>

    <nz-form-control [nzErrorTip]="'main.form.errors.required-tip' | translate">
           <textarea
             rows="2"
             formControlName="homework"
             nz-input
             [placeholder]="'calendar.conduct-lesson.homework' | translate"
             nzAutosize maxlength="2048"
           ></textarea>
    </nz-form-control>
  </nz-form-item>
</form>
