<form nz-form
      [formGroup]="conductLessonFormService.lessonForm"
      nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzFor="notes">
      {{ 'calendar.conduct-lesson.notes' | translate }}
    </nz-form-label>

    <nz-form-control>

      <math-textarea-ai
            formControlName="notes"
            [placeholder]="'calendar.conduct-lesson.notes' | translate"
            [maxLength]="2048"
            [allowEmptyTextToGenerate]="true"
            [gptQuery]="applyAINoteRequest"
            (generatedValueStatusChanged)="conductLessonFormService.lessonForm.get('notesAiStatus').patchValue($event)"
            ></math-textarea-ai>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="links">
      {{ 'calendar.conduct-lesson.links' | translate }}
    </nz-form-label>

    <nz-form-control class="links">
      @for (link of conductLessonFormService.links.controls; track link.value?.name; let index = $index) {
        <nz-tag
          nzMode="closeable"
          (nzOnClose)="onRemoveLink(index)"
          nz-tooltip
          [nzTooltipTitle]="link.value"
        >
          <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
        </nz-tag>
      }

      @if (conductLessonFormService.links.controls?.length < 5) {
        @if (!linkInputVisible) {
          <nz-tag class="editable-tag" nzNoAnimation (click)="showLinkInput()">
            <span nz-icon
                  nzType="plus"></span>
            {{ 'calendar.conduct-lesson.new-link' | translate }}
          </nz-tag>
        } @else {
          <input
            #linkInputElement
            nz-input
            nzSize="small"
            type="text"
            [(ngModel)]="linkInputValue"
            [ngModelOptions]="{standalone: true}"
            style="width: 80px;"
            (blur)="handleLinkInputConfirm()"
            (keydown.enter)="handleLinkInputConfirm()"
          />
        }
      }
    </nz-form-control>
  </nz-form-item>

  <div style="margin-bottom: 16px">
    <mathema-info-block [_type]="'info'"
                        [text]="'calendar.conduct-lesson.homework-materials-tip' | translate">
    </mathema-info-block>
  </div>

  <nz-form-item>
    <math-upload-materials></math-upload-materials>
  </nz-form-item>
</form>
