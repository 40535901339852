import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  input,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import SharedModule from '@shared/shared.module';
import { ConductLessonFormService } from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/conduct-lesson-form.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/services/notification.service';
import {
  ConductedLessonCommentData,
  GPTMessageResponse,
  GptService,
} from '@shared/services/gpt.service';
import {
  goodNotesDataMap,
  needAttentionDataMap,
} from '@modules/components/schedule/calendar/modals/conduct-lesson-modal/const/conduct-lesson-rates-list.const';
import { finalize, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribeComponent } from '@shared/components/auto-unsubscribe/auto-unsubscribe.component';
import { NzModule } from '@modules/nz-module';
import { UploadMaterialsComponent } from '@shared/components/upload-materials/upload-materials.component';
import { TextareaAiComponent } from '@app/shared/components/textarea-ai/textarea-ai.component';
import { Observable, of } from 'rxjs';
import { MathemaInfoBlockComponent } from "@shared/components/mathema-info-block/mathema-info-block.component";

@Component({
  selector: 'math-additional-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslatePipe,
    NzModule,
    UploadMaterialsComponent,
    TextareaAiComponent,
    MathemaInfoBlockComponent
],
  templateUrl: './additional-info.component.html',
  styleUrl: './additional-info.component.scss',
})
export class AdditionalInfoComponent
  extends AutoUnsubscribeComponent
  implements AfterViewInit
{
  public lessonId = input<string>();

  protected conductLessonFormService = inject(ConductLessonFormService);
  protected notificationService = inject(NotificationService);
  protected translateService = inject(TranslateService);
  protected gptService = inject(GptService);
  
  protected applyAINoteRequest = this.applyAINote$.bind(this);

  public linkInputVisible = false;
  public linkInputValue = '';

  protected isFocusedNotes = false;

  @ViewChild('linkInputElement', { static: false })
  linkInputElement?: ElementRef;

  @ViewChild(UploadMaterialsComponent) mathUpload: UploadMaterialsComponent | undefined;

  constructor() {
    super();
  }

  public ngAfterViewInit(): void {
    this.mathUpload.fileList = [];
    this.conductLessonFormService.mathUpload = this.mathUpload;

    this.mathUpload.fileList = [...this.conductLessonFormService.lessonFiles];
    this.mathUpload.cdRef.detectChanges();
  }

  private applyAINote$(): Observable<GPTMessageResponse> {
    this.conductLessonFormService.isDisabledSubmit = true;
    const form = this.conductLessonFormService.lessonForm.value;

    const { goodNotes, needAttention } = this.getHumanizedRates(form.teacherRates);

    const data: ConductedLessonCommentData = {
      customMessage: form.notes,
      lessonSubject: form.subject,
      lessonId: this.lessonId(),
      goodNotes,
      needAttention,
      homeworkCompletion: this.conductLessonFormService.lessonForm.value.homeworkCompletion,
    };

    return this.gptService
      .createConductedLessonComment(data)
      .pipe(
        finalize(() => {
          this.conductLessonFormService.isDisabledSubmit = false;
        }),
        takeUntil(this.destroy$)
      )
  }

  private getHumanizedRates(teacherRates: string[]): Record<string, string[]> {
    return [...teacherRates].reduce(
      (acc, lessonRate) => {
        if (goodNotesDataMap[lessonRate]) {
          acc.goodNotes.push(
            this.translateService.instant(goodNotesDataMap[lessonRate])
          );
        } else {
          acc.needAttention.push(
            this.translateService.instant(needAttentionDataMap[lessonRate])
          );
        }

        return acc;
      },
      { goodNotes: [], needAttention: [] }
    );
  }

  public showLinkInput(): void {
    this.linkInputVisible = true;
    setTimeout(() => {
      this.linkInputElement?.nativeElement?.focus();
    }, 10);
  }

  public handleLinkInputConfirm(): void {
    if (this.linkInputValue.length > 255) {
      this.linkInputValue = '';
      this.linkInputVisible = false;
      return;
    }

    if (
      this.linkInputValue &&
      this.conductLessonFormService.links.controls
        .map((el) => el.value)
        .indexOf(this.linkInputValue) === -1
    ) {
      this.conductLessonFormService.links.push(
        new UntypedFormControl(this.linkInputValue)
      );
    }

    this.linkInputValue = '';
    this.linkInputVisible = false;
  }

  public onRemoveLink(index): void {
    this.conductLessonFormService.links.removeAt(index);
  }

  public sliceLinkValue(link: string): string {
    const isLongLink = link.length > 15;
    return isLongLink ? `${link.slice(0, 15)}...` : link;
  }
}
