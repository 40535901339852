import { DateTime } from 'luxon';
import { Component, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { EMPTY, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AuthService } from '../../../../auth/auth.service';
import ScheduleCalendarService from '../../schedule-calendar.service';
import { SkipLessonReason } from '@shared/services/lessons/dto/skip-lesson.dto';
import { MathemaModalConfirmComponent, MathemaModalConfirmParams } from '@shared/components/modal-confirm/modal-confirm.component';
import LessonsApiService from '@shared/services/lessons/lessons-api.service';
import ScheduleApiService from '../../../schedule-api.service';

@Component({
  selector: 'math-skip-lesson-modal',
  templateUrl: './skip-lesson-modal.component.html',
  styleUrls: ['./skip-lesson-modal.component.scss']
})
export class SkipLessonModalComponent implements OnDestroy{

  @Output() onSuccess: EventEmitter<void> = new EventEmitter();
  @Output() onOpenConduct: EventEmitter<void> = new EventEmitter();

  public isLoading = false;
  public notificationNeeded = false;

  public skipReason: typeof SkipLessonReason = SkipLessonReason;

  public activeSkipReason = null;
  public customSkipReason = null;
  public skipReasons: { value: string, disabled?: boolean }[] =
    Object.values(SkipLessonReason).map(value => {
      return {
        value,
        disabled: value === SkipLessonReason.STUDENT_NO_REASON ? this.isAllowedToSkipWithoutReason() : false,
      };
    });

  private subscriptions: Subscription[] = [];

  constructor(
    public readonly modalRef: NzModalRef,
    public readonly authService: AuthService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly lessonsApiService: LessonsApiService,
    private readonly modalService: NzModalService,
    private readonly translateService: TranslateService,
    private readonly scheduleApiService: ScheduleApiService,
    @Inject(NZ_MODAL_DATA) public data: {
      showNotification: boolean
    }
  ) { }

  private isAllowedToSkipWithoutReason(): boolean {
    const { slotItemDate: lessonDate, slotItemTime } = this.scheduleCalendarService.activeSlot.value;
    const lessonStartTime = slotItemTime.split(' - ')[0];

    const now = DateTime.now();
    const lessonTime = DateTime.fromISO(`${lessonDate}T${lessonStartTime}`);

    return now < lessonTime.plus({ minute: 15 });
  }

  public submit(force?: boolean): void {
    const lessonId = this.scheduleCalendarService.activeSlot.value.slotItemId;

    this.isLoading = true;

    const subscription = this.lessonsApiService.skipLessonById(lessonId, {
        sendNotification: this.notificationNeeded,
        reason: this.activeSkipReason,
        reasonDescription: this.customSkipReason,
        force,
      }).pipe(
        catchError(() => {
          this.modalRef.close();
          return EMPTY;
        }),
      ).subscribe(({ skipsCauseStudentNoReason, conductsCauseStudentNoReason }) => {
        if (!skipsCauseStudentNoReason) {
          this.onSuccess.emit();
          this.modalRef.close();
        } else if (skipsCauseStudentNoReason >= 1 && !conductsCauseStudentNoReason) {
          this.openConductProposal(() => {
              this.onOpenConduct.emit();
              this.modalRef.close();
          }, () => {
            this.submit(true);
          });
        } else {
          this.openSupportProposal(() => {
            this.submit(true);
          }, () => {
            this.modalRef.close();
          });
        }
      });

    this.subscriptions.push(subscription);
  }

  private openConductProposal(onAccept: () => void, onDecline: () => void): void {
    this.confirmModal(
      {
        header: this.translateService.instant('calendar.skip-lesson.conduct-instead.header'),
        message: this.translateService.instant('calendar.skip-lesson.conduct-instead.message'),
        acceptText: this.translateService.instant('calendar.skip-lesson.conduct-instead.accept'),
        declineText: this.translateService.instant('calendar.skip-lesson.conduct-instead.decline'),
      },
      onAccept,
      onDecline,
    );
  }

  private openSupportProposal(onAccept: () => void, onDecline: () => void): void {
    this.confirmModal(
      {
        header: this.translateService.instant('calendar.skip-lesson.support-warn.header'),
        message: this.translateService.instant('calendar.skip-lesson.support-warn.message'),
        acceptText: this.translateService.instant('calendar.skip-lesson.support-warn.accept'),
        declineText: this.translateService.instant('calendar.skip-lesson.support-warn.decline'),
      },
      onAccept,
      onDecline,
    );
  }

  private confirmModal(
    params: MathemaModalConfirmParams,
    onAccept: () => void,
    onDecline: () => void,
  ): void {
    const modalRef = this.modalService.create({
      nzClosable: false,
      nzFooter: null,
      nzCentered: true,
      nzMaskClosable: false,
      nzBodyStyle: { padding: '0' },
      nzContent: MathemaModalConfirmComponent,
      nzData: {
        params,
      },
    });

    modalRef.componentInstance.onAccept
      .pipe(take(1))
      .subscribe(() => {
      modalRef.close();
      onAccept();
    });

    modalRef.componentInstance.onDecline
      .pipe(take(1))
      .subscribe(() => {
        modalRef.close();
        onDecline();
      });
  }

  public isAcceptDisabled(): boolean {
    if (!this.authService.isTeacher()) {
      return false;
    }

    if (this.activeSkipReason === SkipLessonReason.OTHER) {
      return !this.customSkipReason;
    }

    return !this.activeSkipReason;
  }

  public ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
