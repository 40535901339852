import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { catchError, tap } from 'rxjs/operators';
import StudentsTeachersPairsDto from '@shared/services/deals/dto/students-teachers-pairs.dto';
import ScheduleCalendarService, { ScheduleCalendarFilters } from '../schedule-calendar.service';
import LocalStorageService, { LocalStorageKey } from '@shared/services/local-storage.service';
import StudentsTeachersPairsQueryDto from '@shared/services/deals/dto/students-teachers-pairs-query.dto';
import DealsApiService from '@shared/services/deals/deals-api.service';

@Component({
  selector: 'math-schedule-calendar-client-filters',
  templateUrl: './schedule-calendar-client-filters.component.html',
  styleUrls: ['./schedule-calendar-client-filters.component.scss']
})
export class ScheduleCalendarClientFiltersComponent implements OnInit {

  public filtersData: StudentsTeachersPairsDto;
  public loadingError$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  public studentId: string;
  public teacherId: string;

  constructor(
    private readonly authService: AuthService,
    private readonly scheduleCalendarService: ScheduleCalendarService,
    private readonly localStorageService: LocalStorageService,
    private readonly dealsApiService: DealsApiService,
  ) { }

  public ngOnInit(): void {
    const query: StudentsTeachersPairsQueryDto = {};

    if (this.authService.isClient()) {
      query.clientId = this.authService.user.clientId;
    } else if (this.authService.isStudent()) {
      query.studentId = this.authService.user.studentId;
    }

    this.dealsApiService.getStudentsTeachersPairs(query)
      .pipe(
        tap((response) => {
          if (response.students?.length) {
            const studentId = response.students[0].id;

            const localFilters: ScheduleCalendarFilters = JSON.parse(this.localStorageService.getItem(LocalStorageKey.CLIENT_CALENDAR_FILTERS));
            const isStudentInLocalFilters = localFilters?.studentsIds
              ?.some(studentId => response.students.some(student => student.id === studentId));

            if (isStudentInLocalFilters) {
              this.studentId = localFilters.studentsIds[0];
              this.teacherId = localFilters.teacherId;
            } else {
              this.studentId = studentId;
              this.setFirstTeacherByStudentId(response, studentId);
            }

            this.load();
          } else {
            this.filtersData = null;
            this.loadingError$.next(true);
          }
        }),
        catchError(() => {
          this.filtersData = null;
          this.loadingError$.next(true);
          return EMPTY;
        }),
      )
      .subscribe((response: StudentsTeachersPairsDto) => {
        this.filtersData = response;
      })
  }

  public onStudentSelected(studentId: string): void {
    this.studentId = studentId;
    this.setFirstTeacherByStudentId(this.filtersData, this.studentId);
    this.load();
  }

  public onTeacherSelected(teacherId: string): void {
    this.teacherId = teacherId;
    this.load();
  }

  public filterEnabledTeachers(studentId: string): (teacher: StudentsTeachersPairsDto['teachers'][0]) => boolean {
    return (teacher: StudentsTeachersPairsDto['teachers'][0]): boolean => {
      return teacher.students?.includes(studentId);
    }
  }

  private load(): void {
    const filters: ScheduleCalendarFilters = {
      studentsIds: [this.studentId],
      teacherId: this.teacherId,
    };

    this.localStorageService.setItem(LocalStorageKey.CLIENT_CALENDAR_FILTERS, JSON.stringify(filters));

    this.scheduleCalendarService.calendarFilters.next(filters);
  }

  private setFirstTeacherByStudentId(filtersData: StudentsTeachersPairsDto, studentId: string): void {
    const teacher  = filtersData.teachers.find((teacher) => teacher.students?.includes(studentId));

    if (teacher) {
      this.teacherId = teacher.id;
    }
  }

}
