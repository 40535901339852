import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BaseHttpService } from '@shared/abstractions/base-http.service';
import StudentsTeachersPairsQueryDto from '@shared/services/deals/dto/students-teachers-pairs-query.dto';
import StudentsTeachersPairsDto from '@shared/services/deals/dto/students-teachers-pairs.dto';

@Injectable({
  providedIn: 'root',
})
export default class DealsApiService extends BaseHttpService {
  constructor() {
    super('deals');
  }

  public getStudentsTeachersPairs(query: StudentsTeachersPairsQueryDto): Observable<StudentsTeachersPairsDto> {
    return this.get<StudentsTeachersPairsDto>('pairs/students-teachers', query);
  }
}
