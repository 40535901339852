<div class="report-modal">
  <div class="wrapper" *ngIf="reportData$ | async as report; else loadingOrError">
    <div class="report-loader" *ngIf="isLoading">
      <nz-spin [nzIndicator]="indicatorTemplate" nzSimple></nz-spin>
      <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
    </div>
    <div style="margin-bottom: 16px">
      <mathema-info-block [_type]="'warn'" [text]="'calendar.report.content-warn' | translate"></mathema-info-block>
    </div>
    <div class="report-info">
      <div class="info-block">
        <div class="title">{{'calendar.report.date' | translate}}</div>
        <div class="content date">{{formatDate(report.createdAt)}}</div>
      </div>
      <div class="info-block">
        <div class="title">{{'calendar.report.type' | translate}}</div>
        <div [class]="['content', 'type', report.type]">{{('calendar.report.type-' + report.type) | translate}}</div>
      </div>
      <div class="info-block" *ngIf="report.teacher">
        <div class="title">{{'calendar.report.teacher' | translate}}</div>
        <div class="content">{{report.teacher?.lastName}} {{report.teacher?.firstName}}</div>
      </div>
    </div>
    <form nz-form [formGroup]="reportForm" nzLayout="vertical">
      <nz-form-item>
        <math-upload-materials></math-upload-materials>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label
          [nzRequired]="report.type === reportType.DIAGNOSTIC && $any(reportForm.get('completionPercentage')).hasValidator(Validators.required)"
          nzFor="completionPercentage"
        >{{'calendar.report.completion-percentage-lb' | translate}}</nz-form-label>
        <nz-form-control [nzErrorTip]="errorTplPercentage">
          <input
            formControlName="completionPercentage"
            type="text"
            nz-input
            [placeholder]="'calendar.report.completion-percentage-ph' | translate"
            maxlength="255"
          />
        </nz-form-control>
        <ng-template #errorTplPercentage let-control>
          <ng-container *ngIf="control.errors?.['required']">{{'main.form.errors.required-tip' | translate}}</ng-container>
          <ng-container *ngIf="control.errors?.['maxlength']">{{'main.form.errors.max-length-255' | translate}}</ng-container>
        </ng-template>
      </nz-form-item>
      <nz-form-item class="textarea-count">
        <nz-form-label 
          [nzRequired]="$any(reportForm.get('knowledge')).hasValidator(Validators.required)"
          nzFor="knowledge"
          >
          {{'calendar.report.knowledge' | translate}}
        </nz-form-label>

        <nz-form-control [nzErrorTip]="errorTplKnowledge">
          <math-textarea-ai
            formControlName="knowledge"
            [preRequestText]="'calendar.report.knowledge-regular-default' | translate"
            [placeholder]="'calendar.report.knowledge-ph' | translate"
            [maxLength]="2048"
            [gptQuery]="expandTeachersNoteRequest"
            (generatedValueStatusChanged)="reportForm.get('knowledgeAiStatus').patchValue($event)"
          ></math-textarea-ai>
        </nz-form-control>

        <ng-template 
          #errorTplKnowledge 
          let-control>
          <ng-container 
            *ngIf="control.errors?.['required']"
            >
            {{'main.form.errors.required-tip' | translate}}
          </ng-container>

          <ng-container 
            *ngIf="control.errors?.['minlength']"
            >
            {{'calendar.report.min-length-100' | translate}}
          </ng-container>
        </ng-template>
      </nz-form-item>

      <nz-form-item class="textarea-count">
        <nz-form-label 
          [nzRequired]="$any(reportForm.get('gaps')).hasValidator(Validators.required)"
          nzFor="gaps"
          >
          {{'calendar.report.gaps' | translate}}
        </nz-form-label>

        <nz-form-control 
          [nzErrorTip]="errorTplGaps">
          <math-textarea-ai
            formControlName="gaps"
            [preRequestText]="(firstLessonId ? 'calendar.report.gaps-regular-default' : 'calendar.report.gaps-diagnostic-default') | translate"
            [placeholder]="'calendar.report.gaps-ph' | translate"
            [maxLength]="2048"
            [gptQuery]="expandTeachersNoteRequest"
            (generatedValueStatusChanged)="reportForm.get('gapsAiStatus').patchValue($event)"
          ></math-textarea-ai>
        </nz-form-control>

        <ng-template #errorTplGaps let-control>
          <ng-container 
            *ngIf="control.errors?.['required']"
            >
            {{'main.form.errors.required-tip' | translate}}
          </ng-container>

          <ng-container 
            *ngIf="control.errors?.['minlength']"
            >
            {{'calendar.report.min-length-100' | translate}}
          </ng-container>
        </ng-template>
      </nz-form-item>

      <nz-form-item class="textarea-count">
        <nz-form-label 
          [nzRequired]="$any(reportForm.get('conclusion')).hasValidator(Validators.required)"
          nzFor="conclusion"
          >
          {{'calendar.report.conclusion' | translate}}
        </nz-form-label>

        <nz-form-control 
          [nzErrorTip]="errorTplConclusion"
          >
          <math-textarea-ai
            formControlName="conclusion"
            [preRequestText]="preRequestConclusionText"
            [placeholder]="'calendar.report.conclusion-ph' | translate"
            [maxLength]="2048"
            [allowEmptyTextToGenerate]="isAllowedConclusionGenerateWithEmptyInput()"
            [gptQuery]="expandTeachersConclusionRequest"
            [gptQueryOptions]="expandTeachersConclusionOptions"
            (generatedValueStatusChanged)="reportForm.get('conclusionAiStatus').patchValue($event)"
          ></math-textarea-ai>
        </nz-form-control>

        <ng-template #errorTplConclusion let-control>
          <ng-container 
            *ngIf="control.errors?.['required']">
              {{'main.form.errors.required-tip' | translate}}
          </ng-container>

          <ng-container 
            *ngIf="control.errors?.['minlength']"
            >
            {{'calendar.report.min-length-100' | translate}}
          </ng-container>
        </ng-template>
      </nz-form-item>

      @if (report?.type === ReportType.DIAGNOSTIC) {
        <nz-form-item>
          <nz-form-label 
            [nzRequired]="$any(reportForm.get('recommendation')).hasValidator(Validators.required)"
            nzFor="recommendation"
            >
            {{'calendar.report.recommendation-lb' | translate}}
          </nz-form-label>
          <nz-form-control 
            [nzErrorTip]="'main.form.errors.required-tip' | translate">
            <math-textarea-ai
              formControlName="recommendation"
              [placeholder]="'calendar.report.recommendation-ph' | translate"
              [maxLength]="2048"
              [allowEmptyTextToGenerate]="true"
              [gptQuery]="expandTeachersRecommendationRequest"
              [gptQueryOptions]="expandTeachersRecommendationOptions"
              (generatedValueStatusChanged)="reportForm.get('recommendationAiStatus').patchValue($event)"
            ></math-textarea-ai>
          </nz-form-control>
        </nz-form-item>
      }
      <nz-form-item>
        <nz-form-label nzFor="links">
          {{'calendar.report.links' | translate}}
        </nz-form-label>

        <nz-form-control class="links">
          <nz-tag
            *ngFor="let link of links.controls; let index = index"
            nzMode="closeable"
            (nzOnClose)="onRemoveLink(index)"
            nz-tooltip
            [nzTooltipTitle]="link.value"
          >
            <a [href]="link.value" target="_blank">{{ sliceLinkValue(link.value) }}</a>
          </nz-tag>

          <ng-container *ngIf="links.controls.length < 5">
            <ng-container *ngIf="!linkInputVisible; else linkInput">
              <nz-tag 
                class="editable-tag" 
                nzNoAnimation 
                (click)="showLinkInput()">
                <span nz-icon nzType="plus"></span>
                {{'calendar.report.new-link' | translate}}
              </nz-tag>
            </ng-container>
            <ng-template #linkInput>
              <input
                #linkInputElement
                nz-input
                nzSize="small"
                type="text"
                [(ngModel)]="linkInputValue"
                [ngModelOptions]="{standalone: true}"
                style="width: 80px;"
                (blur)="handleLinkInputConfirm()"
                (keydown.enter)="handleLinkInputConfirm()"
              />
            </ng-template>
          </ng-container>
        </nz-form-control>
      </nz-form-item>
    </form>
    <div class="action-buttons">
      <button 
        class="cancel"
        (click)="onCancel.emit()"
        >
        {{'main.btn.cancel' | translate}}
      </button>

      <button 
        class="accept" 
        [disabled]="reportForm.invalid" 
        (click)="onSubmit()"
        >
        {{(editMode ? 'main.btn.save' : 'calendar.report.accept') | translate}}
      </button>
    </div>
  </div>
</div>

<ng-template #loadingOrError>
  <div class="error">
    <mathema-some-loading-error *ngIf="loadingError$ | async; else loading"></mathema-some-loading-error>
  </div>
  <ng-template #loading>
    <nz-spin [nzIndicator]="indicatorTemplate" nzSimple class="report-modal-loader"></nz-spin>
    <ng-template #indicatorTemplate><span style="font-size: 36px; color: #613CB0;" nz-icon nzType="loading"></span></ng-template>
  </ng-template>
</ng-template>
